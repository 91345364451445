import BackgroundComponent from "../components/backgroundDOTS";
import abu from '../logo/abu.jpg';
import babyliss from '../logo/babyliss.jpg';
import bbc from '../logo/bbc.jpg';
import bio from '../logo/bio.jpg';
import eurostar from '../logo/eurostar.jpg';
import foxs from '../logo/foxs.jpg';
import gsk from '../logo/gsk.jpg';
import lombard from '../logo/lombard.jpg';
import naspers from '../logo/naspers.jpg';
import networkrail from '../logo/networkrail.jpg';
import ntt from '../logo/ntt.jpg';
import pernod from '../logo/pernod.jpg';
import rhs from '../logo/rhs.jpg';
import sarasin from '../logo/sarasin.jpg';
import sky from '../logo/sky.jpg';
import smith from '../logo/smith.jpg';
import sony from '../logo/sony.jpg';
import specsavers from '../logo/specsavers.jpg';
import vie from '../logo/vie.jpg';
import wallstreet from '../logo/wallstreet.jpg';
import westyorkshire from '../logo/westyorkshire.jpg';

const Clients = () => {
    return (
      <>
      <BackgroundComponent/>
      <div className="page business">
      <div className="row">

      <div className="col-12 col-md-3 sidePanel">
          <div className="svg smeImage makeBusiness logoImage"></div>

          <h1>Some of the companies we have worked with</h1>


          {/* <div className="navPanel">

            <ul>
              <li><a href="#analysis">Technology Review</a></li>
              <li><a href="#general">General Audit</a></li>
            </ul>

          </div> */}
        </div>

        <div className="col-12 col-md-8  offset-md-1 pagePanel">
          <div className="pagePanel--section logos">
            <img src={abu} alt="Abu Dhabi Find Wonder"/>
            <img src={babyliss} alt="Babyliss"/>
            <img src={bbc} alt="BBC"/>
            <img src={bio} alt="BIO"/>
            <img src={eurostar} alt="Eurostar"/>
            <img src={foxs} alt="Foxs"/>
            <img src={gsk} alt="GSK"/>
            <img src={lombard} alt="Lombard"/>
            <img src={naspers} alt="Naspers"/>
            <img src={networkrail} alt="Network Rail"/>
            <img src={ntt} alt="NTT Data"/>
            <img src={pernod} alt="Pernod"/>
            <img src={rhs} alt="RHS"/>
            <img src={sarasin} alt="Sarasin"/>
            <img src={sky} alt="Sky"/>
            <img src={smith} alt="Smith and Nephew"/>
            <img src={sony} alt="Sony Interactive"/>
            <img src={specsavers} alt="Specsavers"/>
            <img src={vie} alt="Virgin Interactive Entertainment"/>
            <img src={wallstreet} alt="Wallstreet Systems"/>
            <img src={westyorkshire} alt="West Yorkshire Police"/>
          </div>
        </div>



      </div>
    </div>
      </>
    );
  };
  
  export default Clients;
import ReactDOM from "react-dom/client";
import { Component } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import StandardLayout from "./pages/StandardLayout";
// import HomePageLayout from "./pages/HomePageLayout";
import ScrollToTop from "./components/scrollToTop";
import Home from "./pages/Home";
import Agency from "./pages/Agency";
import Past from './pages/Clients';
import StartUp from "./pages/StartUp";
import Contact from "./pages/Contact";
import Credits from "./pages/Credits";
import NoPage from "./pages/NoPage";
import Projects from "./pages/Projects";
import './scss/bootstrap.scss';
import './custom-scss/main.scss';




class App extends Component {

  render () {
    return (
      <div>
        <BrowserRouter>
        <ScrollToTop />
          <Routes>
            {/* <Route element={<StandardLayout/>}>
            </Route> */}
              <Route element={<StandardLayout/>}>
                <Route path="/" element={<Home/>}/>
                <Route path="/agency" element={<Agency />} />
                <Route path="/past" element={<Past />} />
                <Route path="/startup" element={<StartUp />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/projects" element={<Projects/>}/>
                <Route path="/credits" element={<Credits />} />
                <Route path="*" element={<NoPage />} />
              </Route>
          </Routes>
        </BrowserRouter>

      </div>
    )
  }
}

export default App;


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
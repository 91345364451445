import BackgroundComponent from "../components/backgroundDOTS2";



const Contact = () => {
    return (
    <>
    <BackgroundComponent/>
      <div className="page contact">
        <div className="row">
          <div className="col-12 col-md-3 sidePanel">
            <div className="svg logoImage makeWhite"></div>
            <h1>Contact</h1>

          </div>
          <div className="col-12 col-md-8  offset-md-1 pagePanel">
            <div className="pagePanel--section">
              <h2>Email</h2>
              <h3><a href="mailto:info@overcreative.com"  rel="noreferrer" target="_blank" >info@overcreative.com</a></h3>
            </div>
            <div className="pagePanel--section">
              <h2>Whatsapp</h2>
              <a aria-label="Chat on WhatsApp" href="https://wa.me/+447940565787"  rel="noreferrer" target="_blank" title="Whatsapp"><div className="whatsapp"></div></a>
            </div>


          </div>
        </div>

      </div>

    </>);
  };

  export default Contact;

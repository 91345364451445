import { Link } from "react-router-dom";
import { useState } from "react";
const TopNav = () => {

    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    const hide = () => setIsOpen(false);
    const show = () => setIsOpen(true);

    return (
        <>
            <header className="topNav d-flex flex-wrap  py-3 mb-4 border-bottom">

                <Link to="/" className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none">
                    <h2 className="logo fs-3">OVERCREATIVE</h2>
                </Link>
                <button className="hamburger"   onClick={toggle}>
                    {/* icon from heroicons.com */}
                    <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="white"
                    >
                    <path
                        fillRule="evenodd"
                        d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                        clipRule="evenodd"
                    />
                    </svg>
                </button>
                <div className={`menu ${isOpen ? 'open' : 'closed'}`}>
                    <ul className="nav nav-pills">
                        <li className="nav-item"><Link to="/projects" className="nav-link" onClick={toggle} onBlur={hide} onFocus={show}>Projects</Link></li>
                        <li className="nav-item"><Link to="/startup" className="nav-link" onClick={toggle} onBlur={hide} onFocus={show}>Start Ups</Link></li>
                        <li className="nav-item"><Link to="/agency" className="nav-link"  onClick={toggle} onBlur={hide} onFocus={show}>Agency</Link></li>
                        <li className="nav-item"><Link to="/past" className="nav-link"  onClick={toggle} onBlur={hide} onFocus={show}>Past</Link></li>
                        <li className="nav-item"><Link to="/contact" className="nav-link" onClick={toggle} onBlur={hide} onFocus={show}>Contact</Link></li>
                    </ul>
                </div>
            </header>
        </>
        )
        };

export default TopNav;




import { Link } from "react-router-dom";
import BackgroundComponent from "../components/backgroundWAVES";
const Home = () => {
    return (
      <>

      <div className="page home">
      <BackgroundComponent/>
      <div className="row">

      <div className="col-12 col-md-3 sidePanel">

          <div className="svg oc-logo-black  logoImage"></div>
          <h1>Creative Solutions for Digital Problems</h1>
        </div>

        <div className="col-12 col-md-8  offset-md-1 pagePanel">


        <div className="pagePanel--section">
            <h2>Development</h2>
            <p>Discover what is possible through prototyping and the use of cutting edge technologies.</p>
            <div className="homeNav">
              <nav>
                <ul>
                  <li>
                    <Link to="/projects">
                    <div className="logoImage makeHome machineLearning "></div>
                    <h2>Projects</h2>

                    </Link>
                  </li>
      
                </ul>
              </nav>
            </div>
          </div>
 

          <div className="pagePanel--section">
            <h2>Consultancy</h2>
            <p>We specialise in helping our clients from all sectors achieve their digital goals. </p>
            <div className="homeNav">
              <nav>
                <ul className="multi">
                <li>
                    <Link to="/startup">
                    <div className="logoImage makeHome startupImage"></div>
                    <h2>Start Ups</h2>

                    </Link>
                  </li>
                  <li>
                    <Link to="/agency">

                    <div className="logoImage makeHome agencyImage"></div>
                    <h2>Agency</h2>
                    </Link>
                  </li>
                  {/* <li>
                    <Link to="/business">

                    <div className="logoImage makeHome smeImage"></div>
                    <h2>SME</h2>
                    </Link>
                  </li> */}
 
                </ul>
              </nav>
            </div>
          </div>



        </div>



      </div>
    </div>
    </>
    );
  };

  export default Home;